/**
* This file was @generated using pocketbase-typegen
*/

import type PocketBase from 'pocketbase'
import type { RecordService } from 'pocketbase'

export enum Collections {
	Authorigins = "_authOrigins",
	Externalauths = "_externalAuths",
	Mfas = "_mfas",
	Otps = "_otps",
	Superusers = "_superusers",
	Attendance = "attendance",
	Contacts = "contacts",
	Dependants = "dependants",
	Rates = "rates",
	Schedules = "schedules",
	Users = "users",
}

// Alias types for improved usability
export type IsoDateString = string
export type RecordIdString = string
export type HTMLString = string

// System fields
export type BaseSystemFields<T = never> = {
	id: RecordIdString
	collectionId: string
	collectionName: Collections
	expand?: T
}

export type AuthSystemFields<T = never> = {
	email: string
	emailVisibility: boolean
	username: string
	verified: boolean
} & BaseSystemFields<T>

// Record types for each collection

export type AuthoriginsRecord = {
	collectionRef: string
	created?: IsoDateString
	fingerprint: string
	id: string
	recordRef: string
	updated?: IsoDateString
}

export type ExternalauthsRecord = {
	collectionRef: string
	created?: IsoDateString
	id: string
	provider: string
	providerId: string
	recordRef: string
	updated?: IsoDateString
}

export type MfasRecord = {
	collectionRef: string
	created?: IsoDateString
	id: string
	method: string
	recordRef: string
	updated?: IsoDateString
}

export type OtpsRecord = {
	collectionRef: string
	created?: IsoDateString
	id: string
	password: string
	recordRef: string
	sentTo?: string
	updated?: IsoDateString
}

export type SuperusersRecord = {
	created?: IsoDateString
	email: string
	emailVisibility?: boolean
	id: string
	password: string
	tokenKey: string
	updated?: IsoDateString
	verified?: boolean
}

export enum AttendanceStatusOptions {
	"absent" = "absent",
	"present" = "present",
}

export enum AttendanceWhenOptions {
	"am" = "am",
	"pm" = "pm",
	"day" = "day",
}
export type AttendanceRecord = {
	created?: IsoDateString
	date: string
	dependant: RecordIdString
	id: string
	status: AttendanceStatusOptions
	updated?: IsoDateString
	when: AttendanceWhenOptions
}

export enum ContactsRelationshipOptions {
	"guardian" = "guardian",
	"relative" = "relative",
	"other" = "other",
}
export type ContactsRecord = {
	created?: IsoDateString
	dependant: RecordIdString
	email?: string
	id: string
	name: string
	phone?: string
	relationship: ContactsRelationshipOptions
	updated?: IsoDateString
}

export type DependantsRecord = {
	attendance?: RecordIdString[]
	contacts?: RecordIdString[]
	created?: IsoDateString
	id: string
	isActive?: boolean
	name: string
	provider?: RecordIdString
	rates?: RecordIdString[]
	schedules?: RecordIdString[]
	updated?: IsoDateString
}

export type RatesRecord = {
	created?: IsoDateString
	dailyRate?: number
	dependant: RecordIdString[]
	halfDayRate?: number
	id: string
	startDate: string
	updated?: IsoDateString
}

export enum SchedulesSundayOptions {
	"am" = "am",
	"pm" = "pm",
	"day" = "day",
}

export enum SchedulesMondayOptions {
	"am" = "am",
	"pm" = "pm",
	"day" = "day",
}

export enum SchedulesTuesdayOptions {
	"am" = "am",
	"pm" = "pm",
	"day" = "day",
}

export enum SchedulesWednesdayOptions {
	"am" = "am",
	"pm" = "pm",
	"day" = "day",
}

export enum SchedulesThursdayOptions {
	"am" = "am",
	"pm" = "pm",
	"day" = "day",
}

export enum SchedulesFridayOptions {
	"am" = "am",
	"pm" = "pm",
	"day" = "day",
}

export enum SchedulesSaturdayOptions {
	"am" = "am",
	"pm" = "pm",
	"day" = "day",
}
export type SchedulesRecord = {
	created?: IsoDateString
	dependant: RecordIdString
	friday?: SchedulesFridayOptions
	id: string
	monday?: SchedulesMondayOptions
	saturday?: SchedulesSaturdayOptions
	startDate: string
	sunday?: SchedulesSundayOptions
	thursday?: SchedulesThursdayOptions
	tuesday?: SchedulesTuesdayOptions
	updated?: IsoDateString
	wednesday?: SchedulesWednesdayOptions
}

export type UsersRecord = {
	avatar?: string
	created?: IsoDateString
	email: string
	emailVisibility?: boolean
	id: string
	name?: string
	password: string
	tokenKey: string
	updated?: IsoDateString
	verified?: boolean
}

// Response types include system fields and match responses from the PocketBase API
export type AuthoriginsResponse<Texpand = unknown> = Required<AuthoriginsRecord> & BaseSystemFields<Texpand>
export type ExternalauthsResponse<Texpand = unknown> = Required<ExternalauthsRecord> & BaseSystemFields<Texpand>
export type MfasResponse<Texpand = unknown> = Required<MfasRecord> & BaseSystemFields<Texpand>
export type OtpsResponse<Texpand = unknown> = Required<OtpsRecord> & BaseSystemFields<Texpand>
export type SuperusersResponse<Texpand = unknown> = Required<SuperusersRecord> & AuthSystemFields<Texpand>
export type AttendanceResponse<Texpand = unknown> = Required<AttendanceRecord> & BaseSystemFields<Texpand>
export type ContactsResponse<Texpand = unknown> = Required<ContactsRecord> & BaseSystemFields<Texpand>
export type DependantsResponse<Texpand = unknown> = Required<DependantsRecord> & BaseSystemFields<Texpand>
export type RatesResponse<Texpand = unknown> = Required<RatesRecord> & BaseSystemFields<Texpand>
export type SchedulesResponse<Texpand = unknown> = Required<SchedulesRecord> & BaseSystemFields<Texpand>
export type UsersResponse<Texpand = unknown> = Required<UsersRecord> & AuthSystemFields<Texpand>

// Types containing all Records and Responses, useful for creating typing helper functions

export type CollectionRecords = {
	_authOrigins: AuthoriginsRecord
	_externalAuths: ExternalauthsRecord
	_mfas: MfasRecord
	_otps: OtpsRecord
	_superusers: SuperusersRecord
	attendance: AttendanceRecord
	contacts: ContactsRecord
	dependants: DependantsRecord
	rates: RatesRecord
	schedules: SchedulesRecord
	users: UsersRecord
}

export type CollectionResponses = {
	_authOrigins: AuthoriginsResponse
	_externalAuths: ExternalauthsResponse
	_mfas: MfasResponse
	_otps: OtpsResponse
	_superusers: SuperusersResponse
	attendance: AttendanceResponse
	contacts: ContactsResponse
	dependants: DependantsResponse
	rates: RatesResponse
	schedules: SchedulesResponse
	users: UsersResponse
}

// Type for usage with type asserted PocketBase instance
// https://github.com/pocketbase/js-sdk#specify-typescript-definitions

export type TypedPocketBase = PocketBase & {
	collection(idOrName: '_authOrigins'): RecordService<AuthoriginsResponse>
	collection(idOrName: '_externalAuths'): RecordService<ExternalauthsResponse>
	collection(idOrName: '_mfas'): RecordService<MfasResponse>
	collection(idOrName: '_otps'): RecordService<OtpsResponse>
	collection(idOrName: '_superusers'): RecordService<SuperusersResponse>
	collection(idOrName: 'attendance'): RecordService<AttendanceResponse>
	collection(idOrName: 'contacts'): RecordService<ContactsResponse>
	collection(idOrName: 'dependants'): RecordService<DependantsResponse>
	collection(idOrName: 'rates'): RecordService<RatesResponse>
	collection(idOrName: 'schedules'): RecordService<SchedulesResponse>
	collection(idOrName: 'users'): RecordService<UsersResponse>
}
