import { useForm } from "@tanstack/react-form";
import { useMutation } from "@tanstack/react-query";
import { useLoaderData, useNavigate } from "@tanstack/react-router";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { PlusCircle, X } from "lucide-react";
import React from "react";
import { z } from "zod";
import { pb } from "../../pocketbase";
import {
  ContactsRelationshipOptions,
  SchedulesSundayOptions,
} from "../../pocketbase-types";
import { Contact, Dependant, Schedule } from "../../types";

const days: Array<keyof Schedule> = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
] as const;

const ScheduleButton: React.FC<{
  checked: boolean;
  onChange: () => void;
  label: string;
}> = ({ checked, onChange, label }) => (
  <button
    type="button"
    onClick={onChange}
    className={`
      px-3 py-1 rounded-md text-sm font-medium
      transition-all duration-200 ease-in-out
      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-opacity-50
      ${
        checked
          ? "bg-indigo-600 text-white hover:bg-indigo-700 focus:ring-indigo-500"
          : "bg-gray-700 text-gray-300 hover:bg-gray-600 focus:ring-gray-500"
      }
    `}
  >
    {label}
  </button>
);

const EditChildForm: React.FC = () => {
  const data = useLoaderData({
    from: "/edit-child/$childId",
  });

  const activeSchedule = data?.expand?.schedules
    ?.sort(
      (a, b) =>
        new Date(a.startDate).valueOf() - new Date(b.startDate).valueOf(),
    )
    .at(0);

  const activeRates = data?.expand?.rates
    ?.sort(
      (a, b) =>
        new Date(a.startDate).valueOf() - new Date(b.startDate).valueOf(),
    )
    .at(0);

  const navigate = useNavigate();

  const saveChildMutation = useMutation({
    mutationFn: async (value: Omit<Dependant, "id">) => {
      // Naive updates for now
      await pb.collection("dependants").update(data.id, {
        name: value.name,
        // dailyRate: value.dailyRate,
        // halfDayRate: value.halfDayRate,
        // schedule: value.schedule,
      });

      // Update contacts
      // Remove contacts if we need to
      const contactsToRemove =
        data?.expand?.contacts?.filter(
          (contact) =>
            !value.contacts.some((newContact) => newContact.id === contact.id),
        ) ?? [];

      for (const contact of contactsToRemove) {
        await pb.collection("contacts").delete(contact.id);
      }

      await pb.collection("dependants").update(data.id, {
        "contacts-": contactsToRemove.map((contact) => contact.id),
      });

      // If any contacts have an ID beginning with "new-", create a new record
      // Otherwise, update the existing record
      for (const contact of value.contacts) {
        if (contact.id.startsWith("new-")) {
          const response = await pb.collection("contacts").create({
            dependant: data.id,
            name: contact.name,
            phone: contact.phone,
            email: contact.email,
            relationship: contact.relationship,
          });

          await pb.collection("dependants").update(data.id, {
            "contacts+": [response.id],
          });
        } else {
          await pb.collection("contacts").update(contact.id, {
            name: contact.name,
            phone: contact.phone,
            email: contact.email,
            relationship: contact.relationship,
          });
        }
      }

      await navigate({ to: "/manage-children" });

      return value;
    },
  });

  const form = useForm({
    defaultValues: {
      name: data?.name ?? "",
      dailyRate: activeRates?.dailyRate ?? 0,
      halfDayRate: activeRates?.halfDayRate ?? 0,
      isActive: data?.isActive ?? true,
      provider: data?.provider ?? "",
      contacts:
        data?.expand?.contacts?.map((contact) => {
          return {
            id: contact.id,
            name: contact.name,
            phone: contact.phone ?? "",
            email: contact.email ?? "",
            relationship: contact.relationship,
          } as Contact;
        }) ?? [],
      schedule: activeSchedule,
    },
    onSubmit: async ({ value }) => {
      // Do something with form data
      await saveChildMutation.mutateAsync(value);
    },
    validatorAdapter: zodValidator(),
  });

  return (
    <div className="space-y-8 bg-gray-800 shadow rounded-lg p-8">
      <h2 className="text-2xl font-bold text-gray-100 mb-6">Edit Child</h2>

      <form
        className="space-y-8"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();

          form.handleSubmit();
        }}
      >
        <div className="space-y-6">
          <form.Field
            name="name"
            validators={{
              onChange: z.string().min(2),
            }}
            children={(field) => {
              return (
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-300 mb-2"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id={field.name}
                    name={field.name}
                    value={field.state.value}
                    onChange={(e) => field.handleChange(e.target.value)}
                    className="block w-full rounded-md bg-gray-700 border-gray-600 text-gray-100 focus:border-indigo-500 focus:ring-indigo-500 focus:ring-opacity-50 py-3 px-4"
                    required
                  />
                </div>
              );
            }}
          />

          <form.Field
            name="dailyRate"
            children={(field) => {
              return (
                <div>
                  <label
                    htmlFor="dailyRate"
                    className="block text-sm font-medium text-gray-300 mb-2"
                  >
                    Daily Rate
                  </label>
                  <input
                    type="number"
                    id={field.name}
                    name={field.name}
                    value={field.state.value}
                    onChange={(e) => field.handleChange(Number(e.target.value))}
                    className="block w-full rounded-md bg-gray-700 border-gray-600 text-gray-100 focus:border-indigo-500 focus:ring-indigo-500 focus:ring-opacity-50 py-3 px-4"
                    required
                  />
                </div>
              );
            }}
          />

          <form.Field
            name="halfDayRate"
            children={(field) => {
              return (
                <div>
                  <label
                    htmlFor="halfDayRate"
                    className="block text-sm font-medium text-gray-300 mb-2"
                  >
                    Half Day Rate
                  </label>
                  <input
                    type="number"
                    id={field.name}
                    name={field.name}
                    value={field.state.value}
                    onChange={(e) => field.handleChange(Number(e.target.value))}
                    className="block w-full rounded-md bg-gray-700 border-gray-600 text-gray-100 focus:border-indigo-500 focus:ring-indigo-500 focus:ring-opacity-50 py-3 px-4"
                    required
                  />
                </div>
              );
            }}
          />
        </div>

        <div className="space-y-4">
          <h3 className="text-lg font-medium text-gray-200 mb-4">
            Emergency Contacts
          </h3>
          <form.Field name="contacts" mode="array">
            {(field) => {
              return (
                <div className="space-y-4">
                  {field.state.value.map((contact, index) => (
                    <div
                      key={contact.id}
                      className="bg-gray-700 rounded-lg p-4 space-y-4"
                    >
                      <div className="flex justify-between items-center">
                        <h4 className="text-md font-medium text-gray-300">
                          Contact {index + 1}
                        </h4>
                        <button
                          type="button"
                          className="text-red-400 hover:text-red-300 focus:outline-none"
                          onClick={() => field.removeValue(index)}
                        >
                          <X size={20} />
                        </button>
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <form.Field name={`contacts[${index}].name`}>
                          {(nameField) => {
                            return (
                              <div>
                                <label className="block text-sm font-medium text-gray-300 mb-1">
                                  Name
                                </label>
                                <input
                                  type="text"
                                  value={nameField.state.value}
                                  className="block w-full rounded-md bg-gray-600 border-gray-500 text-gray-100 focus:border-indigo-500 focus:ring-indigo-500 focus:ring-opacity-50 py-2 px-3"
                                  required
                                  onChange={(e) =>
                                    nameField.handleChange(e.target.value)
                                  }
                                />
                              </div>
                            );
                          }}
                        </form.Field>

                        <form.Field name={`contacts[${index}].relationship`}>
                          {(relationshipField) => {
                            return (
                              <div>
                                <label className="block text-sm font-medium text-gray-300 mb-1">
                                  Relationship
                                </label>
                                <select
                                  value={relationshipField.state.value}
                                  className="block w-full rounded-md bg-gray-600 border-gray-500 text-gray-100 focus:border-indigo-500 focus:ring-indigo-500 focus:ring-opacity-50 py-2 px-3"
                                  required
                                  onChange={(e) => {
                                    switch (e.target.value) {
                                      case ContactsRelationshipOptions.guardian:
                                        relationshipField.handleChange(
                                          e.target.value,
                                        );
                                        break;
                                      case ContactsRelationshipOptions.relative:
                                        relationshipField.handleChange(
                                          e.target.value,
                                        );
                                        break;
                                      case ContactsRelationshipOptions.other:
                                        relationshipField.handleChange(
                                          e.target.value,
                                        );
                                        break;
                                      default:
                                        throw new Error("Invalid relationship");
                                    }
                                  }}
                                >
                                  <option value="guardian">Guardian</option>
                                  <option value="relative">Relative</option>
                                  <option value="other">Other</option>
                                </select>
                              </div>
                            );
                          }}
                        </form.Field>

                        <form.Field name={`contacts[${index}].phone`}>
                          {(phoneField) => {
                            return (
                              <div>
                                <label className="block text-sm font-medium text-gray-300 mb-1">
                                  Phone (optional)
                                </label>
                                <input
                                  type="tel"
                                  value={phoneField.state.value}
                                  onChange={(e) =>
                                    phoneField.handleChange(e.target.value)
                                  }
                                  className="block w-full rounded-md bg-gray-600 border-gray-500 text-gray-100 focus:border-indigo-500 focus:ring-indigo-500 focus:ring-opacity-50 py-2 px-3"
                                />
                              </div>
                            );
                          }}
                        </form.Field>

                        <form.Field name={`contacts[${index}].email`}>
                          {(emailField) => {
                            return (
                              <div>
                                <label className="block text-sm font-medium text-gray-300 mb-1">
                                  Email (optional)
                                </label>
                                <input
                                  type="email"
                                  value={emailField.state.value}
                                  onChange={(e) =>
                                    emailField.handleChange(e.target.value)
                                  }
                                  className="block w-full rounded-md bg-gray-600 border-gray-500 text-gray-100 focus:border-indigo-500 focus:ring-indigo-500 focus:ring-opacity-50 py-2 px-3"
                                />
                              </div>
                            );
                          }}
                        </form.Field>
                      </div>
                    </div>
                  ))}

                  <button
                    type="button"
                    onClick={() => {
                      field.pushValue({
                        id: `new-${Date.now()}`,
                        name: "",
                        phone: "",
                        email: "",
                        relationship: ContactsRelationshipOptions.guardian,
                      });
                    }}
                    className="flex items-center text-indigo-400 hover:text-indigo-300 focus:outline-none focus:underline"
                  >
                    <PlusCircle size={20} className="mr-2" />
                    Add Emergency Contact
                  </button>
                </div>
              );
            }}
          </form.Field>
        </div>

        <form.Field name="schedule">
          {(field) => {
            return (
              <div>
                <h3 className="text-lg font-medium text-gray-200 mb-4">
                  Schedule
                </h3>
                <div className="space-y-4">
                  {days.map((day) => (
                    <div
                      key={day}
                      className="flex items-center justify-between bg-gray-700 rounded-lg p-4"
                    >
                      <span className="text-md font-medium text-gray-300 capitalize w-24">
                        {day}
                      </span>
                      <div className="flex space-x-2">
                        <form.Field name={`schedule.${day}`}>
                          {(dayField) => (
                            <>
                              <ScheduleButton
                                checked={field.state.value?.[day] === "am"}
                                onChange={() => {
                                  dayField.handleChange(
                                    SchedulesSundayOptions.am,
                                  );
                                }}
                                label="AM"
                              />
                              <ScheduleButton
                                checked={field.state.value?.[day] === "pm"}
                                onChange={() => {
                                  dayField.handleChange(
                                    SchedulesSundayOptions.pm,
                                  );
                                }}
                                label="PM"
                              />
                              <ScheduleButton
                                checked={field.state.value?.[day] === "day"}
                                onChange={() => {
                                  dayField.handleChange(
                                    SchedulesSundayOptions.day,
                                  );
                                }}
                                label="Full Day"
                              />
                            </>
                          )}
                        </form.Field>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          }}
        </form.Field>

        <form.Subscribe
          selector={(state) => [state.canSubmit, state.isSubmitting]}
          children={([canSubmit, isSubmitting]) => (
            <button
              type="submit"
              disabled={!canSubmit || isSubmitting}
              className="w-full px-4 py-3 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 disabled:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 focus:ring-opacity-50 mt-6"
            >
              Update Child
            </button>
          )}
        />
      </form>
    </div>
  );
};

export default EditChildForm;
