import { createFileRoute } from "@tanstack/react-router";
import ManageChildren from "../components/ManageChildren/ManageChildren";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

export const Route = createFileRoute("/manage-children")({
  component: () => (
    <QueryClientProvider client={queryClient}>
      <ManageChildren />
    </QueryClientProvider>
  ),
});
