import {
  createRootRoute,
  Link,
  Outlet,
  redirect,
} from "@tanstack/react-router";
import { CalendarIcon, LogOut, Users } from "lucide-react";
import { useEffect, useState } from "react";
import { pb } from "../pocketbase";
import React from "react";

const TanStackRouterDevtools =
  process.env.NODE_ENV === "production"
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        })),
      );

function RootComponent() {
  const [isAuthenticated, setIsAuthenticated] = useState(pb.authStore.isValid);

  useEffect(() => {
    const unsubscribe = pb.authStore.onChange(() => {
      setIsAuthenticated(pb.authStore.isValid);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="min-h-screen bg-gray-900">
      <header className="bg-gray-800 shadow">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex items-center justify-between">
          <Link to="/" className="flex items-center">
            <Users className="h-8 w-8 text-indigo-400 mr-2" />
            <h1 className="text-xl font-bold text-gray-100">
              Before & After Care
            </h1>
          </Link>
          <nav className="flex items-center space-x-4">
            <Link
              to="/"
              className="text-indigo-400 hover:text-indigo-300"
              title="Calendar"
            >
              <CalendarIcon className="h-6 w-6" />
            </Link>
            <Link
              to="/manage-children"
              className="text-indigo-400 hover:text-indigo-300"
              title="Manage Children"
            >
              <Users className="h-6 w-6" />
            </Link>
            {isAuthenticated && (
              <Link
                to="/logout"
                className="text-indigo-400 hover:text-indigo-300"
                title="Logout"
              >
                <LogOut className="h-6 w-6" />
              </Link>
            )}
          </nav>
        </div>
      </header>
      <main className="max-w-7xl mx-auto pt-6 sm:px-6 lg:px-8">
        <Outlet />
        <TanStackRouterDevtools />
      </main>
    </div>
  );
}

export const Route = createRootRoute({
  beforeLoad: (ctx) => {
    if (pb.authStore.isValid) {
      return;
    }

    if (ctx.location.href.match(/login|logout/)) {
      return;
    }

    throw redirect({ to: "/login" });
  },
  component: RootComponent,
});
