import { useQuery } from "@tanstack/react-query";
import { Link } from "@tanstack/react-router";
import { format, isBefore, isSameDay } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { Edit, UserCheck, UserX } from "lucide-react";
import React from "react";
import { pb } from "../../pocketbase";
import { DependantsResponse, RatesResponse } from "../../pocketbase-types";

const ManageChildren: React.FC = () => {
  const { isPending, error, data, refetch } = useQuery({
    queryKey: ["dependants"],
    queryFn: async () => {
      return await pb.collection("dependants").getFullList<
        DependantsResponse<{
          rates: RatesResponse[];
        }>
      >({
        expand: "rates",
        sort: "name",
      });
    },
  });

  if (isPending) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleToggleActive = async (child: DependantsResponse) => {
    await pb.collection("dependants").update(child.id, {
      isActive: !child.isActive,
    });

    await refetch();
  };

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const today = format(new Date(), "yyyy-MM-dd");

  return (
    <div className="bg-gray-800 shadow rounded-lg overflow-hidden mx-2 sm:mx-0">
      <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
        <h3 className="text-lg leading-6 font-medium text-gray-100">
          Manage Children
        </h3>
        <Link
          to="/add-child"
          className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Add New Child
        </Link>
      </div>
      <ul className="divide-y divide-gray-700">
        {data.map((child) => {
          const rate = child.expand?.rates?.find((rate) => {
            const startDate = toZonedTime(rate.startDate, timeZone);

            return isBefore(startDate, today) || isSameDay(startDate, today);
          });

          return (
            <li key={child.id} className="px-4 py-4 sm:px-6 hover:bg-gray-700">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <p
                    className={`text-sm font-medium ${child.isActive ? "text-gray-100" : "text-gray-400"}`}
                  >
                    {child.name}
                  </p>
                  <span
                    className={`ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      child.isActive
                        ? "bg-green-100 text-green-800"
                        : "bg-red-100 text-red-800"
                    }`}
                  >
                    {child.isActive ? "Active" : "Inactive"}
                  </span>
                </div>
                <div className="flex items-center space-x-4">
                  <button
                    onClick={() => handleToggleActive(child)}
                    className="text-gray-400 hover:text-gray-100 focus:outline-none"
                    title={
                      child.isActive ? "Mark as Inactive" : "Mark as Active"
                    }
                  >
                    {child.isActive ? (
                      <UserX size={20} />
                    ) : (
                      <UserCheck size={20} />
                    )}
                  </button>
                  <Link
                    to="/edit-child/$childId"
                    params={{ childId: child.id }}
                    className="text-gray-400 hover:text-gray-100 focus:outline-none"
                    title="Edit Child"
                  >
                    <Edit size={20} />
                  </Link>
                </div>
              </div>
              <div className="mt-2 text-sm text-gray-500">
                Daily Rate: ${rate?.dailyRate}
              </div>

              <div className="mt-2 text-sm text-gray-500">
                Half Day Rate: ${rate?.halfDayRate}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ManageChildren;
