/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as ManageChildrenImport } from './routes/manage-children'
import { Route as LogoutImport } from './routes/logout'
import { Route as LoginImport } from './routes/login'
import { Route as AddChildImport } from './routes/add-child'
import { Route as IndexImport } from './routes/index'
import { Route as EditChildChildIdImport } from './routes/edit-child.$childId'

// Create/Update Routes

const ManageChildrenRoute = ManageChildrenImport.update({
  id: '/manage-children',
  path: '/manage-children',
  getParentRoute: () => rootRoute,
} as any)

const LogoutRoute = LogoutImport.update({
  id: '/logout',
  path: '/logout',
  getParentRoute: () => rootRoute,
} as any)

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const AddChildRoute = AddChildImport.update({
  id: '/add-child',
  path: '/add-child',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const EditChildChildIdRoute = EditChildChildIdImport.update({
  id: '/edit-child/$childId',
  path: '/edit-child/$childId',
  getParentRoute: () => rootRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/add-child': {
      id: '/add-child'
      path: '/add-child'
      fullPath: '/add-child'
      preLoaderRoute: typeof AddChildImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/logout': {
      id: '/logout'
      path: '/logout'
      fullPath: '/logout'
      preLoaderRoute: typeof LogoutImport
      parentRoute: typeof rootRoute
    }
    '/manage-children': {
      id: '/manage-children'
      path: '/manage-children'
      fullPath: '/manage-children'
      preLoaderRoute: typeof ManageChildrenImport
      parentRoute: typeof rootRoute
    }
    '/edit-child/$childId': {
      id: '/edit-child/$childId'
      path: '/edit-child/$childId'
      fullPath: '/edit-child/$childId'
      preLoaderRoute: typeof EditChildChildIdImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/add-child': typeof AddChildRoute
  '/login': typeof LoginRoute
  '/logout': typeof LogoutRoute
  '/manage-children': typeof ManageChildrenRoute
  '/edit-child/$childId': typeof EditChildChildIdRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/add-child': typeof AddChildRoute
  '/login': typeof LoginRoute
  '/logout': typeof LogoutRoute
  '/manage-children': typeof ManageChildrenRoute
  '/edit-child/$childId': typeof EditChildChildIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/add-child': typeof AddChildRoute
  '/login': typeof LoginRoute
  '/logout': typeof LogoutRoute
  '/manage-children': typeof ManageChildrenRoute
  '/edit-child/$childId': typeof EditChildChildIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/add-child'
    | '/login'
    | '/logout'
    | '/manage-children'
    | '/edit-child/$childId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/add-child'
    | '/login'
    | '/logout'
    | '/manage-children'
    | '/edit-child/$childId'
  id:
    | '__root__'
    | '/'
    | '/add-child'
    | '/login'
    | '/logout'
    | '/manage-children'
    | '/edit-child/$childId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AddChildRoute: typeof AddChildRoute
  LoginRoute: typeof LoginRoute
  LogoutRoute: typeof LogoutRoute
  ManageChildrenRoute: typeof ManageChildrenRoute
  EditChildChildIdRoute: typeof EditChildChildIdRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AddChildRoute: AddChildRoute,
  LoginRoute: LoginRoute,
  LogoutRoute: LogoutRoute,
  ManageChildrenRoute: ManageChildrenRoute,
  EditChildChildIdRoute: EditChildChildIdRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/add-child",
        "/login",
        "/logout",
        "/manage-children",
        "/edit-child/$childId"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/add-child": {
      "filePath": "add-child.tsx"
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/logout": {
      "filePath": "logout.tsx"
    },
    "/manage-children": {
      "filePath": "manage-children.tsx"
    },
    "/edit-child/$childId": {
      "filePath": "edit-child.$childId.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
