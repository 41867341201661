import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import EditChildForm from "../components/EditChildForm/EditChildForm";
import { pb } from "../pocketbase";
import {
  ContactsResponse,
  DependantsResponse,
  RatesResponse,
  SchedulesResponse,
} from "../pocketbase-types";

const queryClient = new QueryClient();

export const Route = createFileRoute("/edit-child/$childId")({
  // Handle 404
  loader: ({ params }) => {
    return pb.collection("dependants").getOne<
      DependantsResponse<{
        contacts: ContactsResponse[];
        rates: RatesResponse[];
        schedules?: SchedulesResponse[];
      }>
    >(params.childId, {
      expand: "contacts,rates,schedules",
    });
  },
  component: () => (
    <QueryClientProvider client={queryClient}>
      <EditChildForm />
    </QueryClientProvider>
  ),
});
