import { createFileRoute, redirect } from "@tanstack/react-router";
import { pb } from "../pocketbase";

export const Route = createFileRoute("/logout")({
  beforeLoad: async () => {
    pb.authStore.clear();

    throw redirect({ to: "/" });
  },
});
